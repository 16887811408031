import axios from 'axios'

const API_PREFIX = "movimientos-traslados-estadisticas";

const ENDPOINTS = {
    semanasRetiros: params => axios(`${API_PREFIX}/semanas-retiros`, { params }),
    informeRetiros: params => axios(`${API_PREFIX}/informe-retiros`, { params }),
    informeRetirosCedis: params => axios(`${API_PREFIX}/informe-retiros-cedis`, { params }),
};

export default ENDPOINTS
